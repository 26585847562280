.mainBody {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  height: 350px;
  background-color: rgb(226, 236, 232);
  border-radius: 8px;
  text-align: center;
}

.title {
  font-size: 22px;
}

p {
  color: rgb(56, 66, 56);
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  height: 40px;
  width: 150px;
  color: white;
  background-color: rgb(56, 66, 56);
  outline: none;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

@media (max-width: 700px) {
  .mainBody {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .card {
    margin-bottom: 20px;
  }
}
