* {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 0;
  margin: 0;
}
body {
  background-color: rgb(65, 80, 70);
}
h1 {
  text-align: center;
  padding: 30px 0 100px 0;
  font-size: 42px;
  color: white;
}
